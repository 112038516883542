<template>
  <BCard body>
    <div class="d-flex justify-between">
      <div class="flex items-start">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="confirmBatalkan()"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="font-semibold text-black mb-2 text-2xl">
          Ajukan Tambah Produk
        </div>
      </div>
      <div class="space-x-4">
        <BButton
          :variant="handleVariantButton()"
          :disabled="handleDisableTambah()"
          @click.once="onFinish"
        >
          Tambah Sekarang
        </BButton>
      </div>
    </div>
    <div class="border mt-2 p-2">
      <div class="d-flex justify-between">
        <div class="d-flex flex-row items-center">
          <div class="mr-1">
            <b-avatar
              :src="detail.image_logo_url"
            />
          </div>
          <div class="d-flex flex-column items-start text-black">
            <div class="font-medium">
              {{ detail.warehouse_name }}
            </div>
            <div class="">
              {{ formatDate(detail.join_date) }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 text-black space-y-4">
        <div class="d-flex">
          <div class="w-50 d-flex">
            <div class="w-50 font-semibold">
              Kota
            </div>
            <div class="w-50">
              {{ detail.city_name }}
            </div>
          </div>
          <div class="w-50 d-flex">
            <div class="w-50 font-semibold">
              Status
            </div>
            <div class="w-50">
              <div class="d-flex">
                <div :class="handleAvailability(detail.availability)">
                  {{ detail.availability }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-50 d-flex">
            <div class="w-50 font-semibold">
              Owner
            </div>
            <div class="w-50">
              {{ detail.owner }}
            </div>
          </div>
          <div class="w-50 d-flex">
            <div class="w-50 font-semibold">
              Alamat gudang
            </div>
            <div class="w-50">
              {{ detail.detail_address }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-end">
      <b-input-group class="mt-2 w-25 input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="searchProduct"
          placeholder="Cari nama produk"
          debounce="500"
        />
      </b-input-group>
    </div>
    <div class="mt-1">
      <BOverlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          id="table"
          ref="selectableTable"
          :items="products"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(checkbox)="data">
            <BCheckbox
              v-if="data.item.is_bundling === 0"
              v-model="selected"
              :value="data.item"
              @input="checkVolumetric(selected[selected.length - 1])"
            />
            <BCheckbox
              v-else
              v-model="selected"
              v-b-tooltip.hover.top="isProductIncomplete(data.item) ? 'Produk satuan penyusun bundling harus dipilih terlebih dahulu' : ''"
              :value="data.item"
              :disabled="isProductIncomplete(data.item)"
              @input="checkVolumetric(selected[selected.length - 1])"
            />
          </template>
          <template #cell(nama_produk)="data">
            <div class="d-flex items-start gap-2 text-black min-w-[250px]">
              <b-img
                :src="data.item.image_path ? data.item.image_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                fluid
                class="image-product"
              />
              <div class="">
                <div class="font-medium">
                  {{ data.item.product_name }}
                </div>
                <div class="text-primary text-[12px]">
                  SKU : {{ data.item.sku }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(volume)="data">
            <div class="d-flex flex-column min-w-[75px]">
              <div class="">
                P : {{ data.item.product_length }} cm
              </div>
              <div class="">
                L : {{ data.item.product_width }} cm
              </div>
              <div class="">
                T : {{ data.item.product_height }} cm
              </div>
            </div>
          </template>
          <template #cell(harga_barang)="data">
            <div class="min-w-[100px]">
              {{ data.item.range_price }}
            </div>
          </template>
          <template #head(packing_layer)="data">
            <div class="d-flex align-items-center">
              {{ data.label }}
              <div class="ml-[11px]">
                <img
                  id="packingLayerPopover"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                >
                <b-popover
                  triggers="hover"
                  target="packingLayerPopover"
                  placement="top"
                >
                  Lapis bahan packing akan mempengaruhi biaya fulfillment. pastikan isi sesuai kebutuhanmu ya
                  <br>
                  <br>
                  <ul>
                    <li>
                      PL : Plastik
                    </li>
                    <li>
                      BW : Bubble Wrap
                    </li>
                    <li>
                      KD : Kardus
                    </li>
                  </ul>
                </b-popover>
              </div>
            </div>
          </template>
          <template #cell(bahan_packing)="data">
            <b-dropdown
              :text="handlePackingText(data.item.packing_material)"
              class="m-md-2 w-100"
              :disabled="disabledPackingOptions(data.item.id)"
              variant="outline-dark"
            >
              <b-form-group
                v-for="(item, index) in packingOptions"
                :key="index"
                class="p-1 mb-0 w-100"
              >
                <b-form-checkbox
                  v-model="data.item.packing_material[index]"
                  :value="item.value"
                  :unchecked-value="null"
                  @input="data.item.packing_material[index] !== null ? data.item.packing_layer[index] = 1 : data.item.packing_layer[index] = null"
                >{{ item.text }}</b-form-checkbox>
              </b-form-group>
            </b-dropdown>
          </template>
          <template #cell(packing_layer)="data">
            <div class="flex gap-2 min-w-[200px]">
              <b-form-group
                v-for="(value, index) in LabelForm"
                :key="index"
                :label="value"
              >
                <b-input
                  v-model="data.item.packing_layer[index]"
                  :formatter="formatPackingLayer"
                  number
                  :disabled="data.item.packing_material[index] === null"
                />
              </b-form-group>
            </div>
          </template>
          <template #row-details="data">
            <b-row
              class="pl-[10rem] -mt-[1rem]"
            >
              <b-col cols="12">
                <div class="flex">
                  <div
                    v-b-toggle="`bundling-${String(data.index)}`"
                    class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                      alt="bundling"
                      width="16"
                    >
                    <div>Bundling</div>
                  </div>
                </div>
                <b-collapse
                  :id="`bundling-${String(data.index)}`"
                >
                  <div class="border-l-[2px] h-min p-[16px] ml-[3.5rem]">
                    <div class="border rounded-t w-[80%]">
                      <div class="bg-[#FFECE9] py-[8px] px-[28px] rounded-t text-black font-[500]">
                        Produk
                      </div>
                      <b-table
                        :items="data.item.bundle_component"
                        :fields="fieldsBundling"
                      >
                        <template #cell(no)="row">
                          {{ row.index + 1 }}
                        </template>
                        <template #cell(product_name)="row">
                          <div class="flex gap-[16px] items-start">
                            <img
                              :src="row.item.product_image[0] ? row.item.product_image[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                              alt="product"
                              width="52"
                              height="52"
                            >
                            <div class="space-y-[4px]">
                              <div class="font-medium">
                                {{ row.item.product_name }}
                              </div>
                              <div class="text-primary text-[12px]">
                                SKU: {{ row.item.sku }}
                              </div>
                            </div>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <div class="flex ml-[0.8rem]">
                    <div
                      v-b-toggle="`bundling-${String(data.index)}`"
                      class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#F95031] text-white font-[500] cursor-pointer"
                    >
                      <feather-icon
                        icon="ChevronUpIcon"
                      />
                      <div>Tutup</div>
                    </div>
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
          </template>
        </BTable>
      </BOverlay>
    </div>
    <b-modal
      id="modal-failed-submission"
      hide-footer
      hide-header
      modal-class="modal-dark"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-img
        role="button"
        style="cursor:pointer"
        class="float-right"
        src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
        @click="closeModal()"
      />
      <b-col
        md="12"
        class="d-flex justify-content-center pt-3"
      >
        <b-img
          width="100"
          src="https://storage.googleapis.com/komerce/assets/elements/transfer-fail.png"
        />
      </b-col>

      <b-col class="text-center mt-2 mb-3">
        <h4>
          <medium>
            Pengajuanmu gagal dikirim.
          </medium>
        </h4>
        <h6 class="text-black">
          <strong>
            Maaf, kamu tidak bisa melakukan pengajuan tambah produk dikarenakan kamu masih memiliki antrian pengajuan yang belum disetujui
          </strong>
        </h6>
      </b-col>
    </b-modal>
    <b-modal
      id="modal-success-submission"
      hide-footer
      hide-header
      modal-class="modal-dark"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-img
        role="button"
        style="cursor:pointer"
        class="float-right"
        src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
        @click="closeModal()"
      />
      <b-col
        md="12"
        class="d-flex justify-content-center pt-3"
      >
        <b-img
          width="100"
          src="https://storage.googleapis.com/komerce/core/icon-popup-success.png"
        />
      </b-col>

      <b-col class="text-center mt-2 mb-3">
        <h4>
          <medium>
            Pengajuanmu berhasil dikirim.
          </medium>
        </h4>
        <h6 class="text-black">
          <strong>
            Pengajuan tambah produk akan segera dikonfrmasi selambat-lambatnya 2x24 jam.
          </strong>
        </h6>
      </b-col>
    </b-modal>
    <b-modal
      id="modal-blocking-volumetric"
      hide-footer
      hide-header
      body-class="!py-[36px] !px-[32px]"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="flex flex-col space-y-[16px] items-center">
        <div class="text-center text-[24px] font-[600] text-black">
          Lengkapi Data Volumetrik
        </div>
        <div class="text-center text-[16px] text-black">
          Kamu perlu melengkapi data volumetrik berikut agar kamu dapat mendaftarkan produkmu ke gudang Kompack
        </div>
        <b-img
          src="https://storage.googleapis.com/komerce/assets/svg/volumerik-box.svg"
          alt="volumetrik-box"
        />
        <div class="border-[1px] px-[22px] py-[24px] rounded-lg w-full space-y-[16px]">
          <div class="">
            <div class="flex items-center">
              <div class="w-[30%] text-[16px]">
                Panjang
              </div>
              <div class="w-[70%]">
                <b-input-group>
                  <template #append>
                    <b-input-group-text class="!bg-[#E2E2E2]">
                      cm
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="volumetric.product_length"
                    trim
                    :class="errorLength ? `!bg-[#FFEDED]` : ``"
                    :formatter="formatLength"
                  />
                </b-input-group>
              </div>
            </div>
            <div
              v-if="errorLength"
              class="flex"
            >
              <div class="w-[30%]" />
              <small

                class="text-danger w-[70%]"
              >
                Panjang harus diisi
              </small>
            </div>
          </div>
          <div class="">
            <div class="flex items-center">
              <div class="w-[30%] text-[16px]">
                Lebar
              </div>
              <div class="w-[70%]">
                <b-input-group>
                  <template #append>
                    <b-input-group-text class="!bg-[#E2E2E2]">
                      cm
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="volumetric.product_width"
                    trim
                    aria-describedby="width-feedback"
                    :class="errorWidth ? `!bg-[#FFEDED]` : ``"
                    :formatter="formatWidth"
                  />
                </b-input-group>
              </div>
            </div>
            <div
              v-if="errorWidth"
              class="flex"
            >
              <div class="w-[30%]" />
              <small

                class="text-danger w-[70%]"
              >
                Lebar harus diisi
              </small>
            </div>
          </div>
          <div class="">
            <div class="flex items-center">
              <div class="w-[30%] text-[16px]">
                Tinggi
              </div>
              <div class="w-[70%]">
                <b-input-group>
                  <template #append>
                    <b-input-group-text class="!bg-[#E2E2E2]">
                      cm
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="volumetric.product_height"
                    trim
                    aria-describedby="height-feedback"
                    :class="errorHeight ? `!bg-[#FFEDED]` : ``"
                    :formatter="formatHeight"
                  />
                </b-input-group>
              </div>
            </div>
            <div
              v-if="errorHeight"
              class="flex"
            >
              <div class="w-[30%]" />
              <small
                class="text-danger w-[70%]"
              >
                Tinggi harus diisi
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full mt-[24px] space-x-[12px]">
        <div class="w-[50%]">
          <b-button
            variant="outline-primary"
            class="w-full"
            @click="cancelVolumetric()"
          >
            Batal
          </b-button>
        </div>
        <div class="w-[50%]">
          <b-button
            :variant="disabledSubmitVolumetric() ? 'secondary' : 'primary'"
            class="w-full"
            :disabled="disabledSubmitVolumetric()"
            @click="submitVolumetric()"
          >
            Simpan
          </b-button>
        </div>
      </div>
    </b-modal>
  </BCard>
</template>

<script>
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import secureLs from '@/libs/secureLs'
import { newAxiosIns } from '@/libs/axios'

export default {
  data() {
    return {
      loading: false,
      detail: {},
      products: [],
      selected: [],
      partnerId: secureLs.getItem('userData'),
      searchProduct: '',
      packingOptions: [],
      LabelForm: ['PL', 'KD', 'BW'],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'text-black text-capitalize py-1 font-semibold',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black',
        },
        {
          key: 'nama_produk',
          label: 'Nama Produk',
          thClass: 'text-black text-capitalize py-1 font-semibold',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black',
        },
        {
          key: 'volume',
          label: 'Volume',
          thClass: 'text-black text-capitalize py-1 font-semibold',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black',
        },
        {
          key: 'harga_barang',
          label: 'Harga Barang',
          thClass: 'text-black text-capitalize py-1 font-semibold',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black',
        },
        {
          key: 'bahan_packing',
          label: 'Bahan Packing',
          thClass: 'text-black text-capitalize py-1 text-center',
          class: 'bg-white text-center',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'packing_layer',
          label: 'Lapisan Packing',
          thClass: 'text-black text-capitalize py-1 text-center',
          class: 'bg-white text-center',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
      ],
      fieldsBundling: [
        {
          key: 'no',
          label: 'No',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-black',
        },
        {
          key: 'product_name',
          label: 'Nama Produk',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-black',
        },
        {
          key: 'qty',
          label: 'Jumlah',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
          tdClass: 'text-black text-center',
        },
      ],
      wh: JSON.parse(localStorage.getItem('warehouse_id')),

      limits: 50,
      offset: 0,
      lastData: false,

      volumetric: {},
      errorLength: false,
      errorWidth: false,
      errorHeight: false,
    }
  },

  watch: {
    searchProduct: {
      handler() {
        this.fetchProduct()
      },
    },
  },
  created() {
    this.fetchDetailGudangKompack()
    this.fetchPackingOptions()
  },

  mounted() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('table').offsetHeight && !this.loading) {
        this.fetchNextProduct()
      }
    }
  },

  methods: {
    handlePackingText(pm) {
      const selectedOption = this.packingOptions.filter(option => pm.includes(option.value))
      if (selectedOption.length === 1) return selectedOption[0].text
      if (selectedOption.length > 1) return `${selectedOption.length} terpilih`
      return 'Pilih bahan packing'
    },
    async fetchDetailGudangKompack() {
      this.loading = true
      await this.$http_komship.get(`/v1/komship/warehouse/information/${this.$route.params.id}`)
        .then(response => {
          this.detail = response.data.data
          this.loading = false
          this.fetchProduct()
        }).catch(() => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal load data, silahkan coba lagi',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    async fetchProduct() {
      this.offset = 0
      this.loading = true
      await this.$http_komship.get('/v1/komship/submission/product', {
        params: {
          warehouse_id: this.wh,
          name: this.searchProduct,
          limits: this.limits,
          offset: this.offset,
        },
      })
        .then(response => {
          const { data } = response.data
          const updated = data.map(item => {
            const newItem = { ...item }
            newItem.packing_material = [null, null, null]
            newItem.packing_layer = []
            // eslint-disable-next-line no-underscore-dangle
            newItem._showDetails = item.is_bundling === 1 ? true : newItem._showDetails
            return newItem
          })
          if (updated.length < this.limits) {
            this.lastData = true
          } else {
            this.lastData = false
          }
          this.offset = updated.length
          this.loading = false
          this.selected.forEach(obj => {
            const index = updated.findIndex(item => item.id === obj.id)
            if (index !== -1) {
              const selectedProduct = updated[index]
              // eslint-disable-next-line no-param-reassign
              obj.product_length = selectedProduct.product_length
              // eslint-disable-next-line no-param-reassign
              obj.product_width = selectedProduct.product_width
              // eslint-disable-next-line no-param-reassign
              obj.product_height = selectedProduct.product_height
              updated[index] = obj
            }
          })
          this.products = updated
        }).catch(err => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal load data, silahkan coba lagi',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    async fetchNextProduct() {
      if (!this.lastData) {
        this.loading = true
        await this.$http_komship.get('/v1/komship/submission/product', {
          params: {
            warehouse_id: this.wh,
            name: this.searchProduct,
            limits: this.limits,
            offset: this.offset,
          },
        })
          .then(response => {
            const { data } = response.data
            const updated = data.map(item => {
              const newItem = { ...item }
              newItem.packing_material = [null, null, null]
              newItem.packing_layer = []
              // eslint-disable-next-line no-underscore-dangle
              newItem._showDetails = item.is_bundling === 1 ? true : newItem._showDetails
              return newItem
            })
            this.products.push(...updated)
            this.offset += data.length
            if (data.length < this.limits) {
              this.lastData = true
            }
            this.loading = false
          }).catch(() => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            }, 2000)
          })
      }
    },
    async fetchPackingOptions() {
      await this.$http_komship.get('/v1/select-option/packing')
        .then(response => {
          const packing = response.data.data.map(data => ({
            value: data.id,
            text: data.name,
          }))
          this.packingOptions = packing
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal load data, silahkan coba lagi',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    async onFinish() {
      const dataProduct = this.selected.map(product => ({
        product_id: product.id,
        packing_material: product.packing_material.filter(item => item !== null),
        packing_layer: product.packing_layer.filter(item => item !== null),
      }))

      const payload = {
        partner_id: this.partnerId.partner_detail.id,
        warehouse_id: this.wh,
        submission_type: 2,
        product: dataProduct,
      }

      await this.$http_komship.post('/v1/komship/submission', payload)
        .then(response => {
          if (response.data.code === 200) {
            this.$bvModal.show('modal-success-submission')
            this.fetchProduct()
          } else {
            this.$bvModal.show('modal-failed-submission')
            this.fetchProduct()
          }
        })
        .catch()
    },
    handleDisableTambah() {
      for (let i = 0; i < this.selected.length; i += 1) {
        if (this.selected[i].packing_material.filter(item => item !== null).length === 0) return true
        if (this.selected[i].packing_layer.filter(item => item === 0).length !== 0) return true
      }
      if (this.selected.length === 0) return true
      if (this.products.length === 0) return true
      return false
    },
    handleVariantButton() {
      for (let i = 0; i < this.selected.length; i += 1) {
        if (this.selected[i].packing_material.filter(item => item !== null).length === 0) return 'secondary'
        if (this.selected[i].packing_layer.filter(item => item === 0).length !== 0) return 'secondary'
      }
      if (this.selected.length === 0) return 'secondary'
      if (this.products.length === 0) return 'secondary'
      return 'primary'
    },
    disabledPackingOptions(data) {
      for (let i = 0; i < this.selected.length; i += 1) {
        if (this.selected[i].id === data) return false
      }
      return true
    },
    formatDate(value) {
      return moment(value).format('DD MMMM YYYY')
    },
    handleAvailability(status) {
      if (status === 'Tersedia') {
        return 'status-tersedia'
      }
      return 'status-penuh'
    },
    confirmBatalkan() {
      this.$swal({
        text: 'Kamu yakin ingin membatalkan tambah produk?',
        icon: 'warning',
        iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
        showCancelButton: true,
        cancelButtonText: 'Tidak',
        confirmButtonText: 'Ya',
        customClass: {
          icon: 'border-0 w-50 my-5',
          confirmButton: 'btn btn-outline-primary mr-1 px-5',
          cancelButton: 'btn btn-primary px-4',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$router.go(-1)
        }
      })
    },
    closeModal() {
      this.$router.push({
        path: `/search-gudang/detail/${this.$route.params.id}`,
      })
    },
    checkVolumetric(data) {
      if (data) {
        if (
          (data.product_length === null || data.product_length === 0)
  || (data.product_width === null || data.product_width === 0)
  || (data.product_height === null || data.product_height === 0)
        ) {
          this.$bvModal.show('modal-blocking-volumetric')
          this.volumetric = {
            ...data,
            product_length: (data.product_length === null || data.product_length === 0) ? '' : data.product_length,
            product_width: (data.product_width === null || data.product_width === 0) ? '' : data.product_width,
            product_height: (data.product_height === null || data.product_height === 0) ? '' : data.product_height,
          }
        }
      }
    },
    formatLength(value) {
      if (value === '') {
        this.errorLength = true
        return value
      }

      const numericValue = value.replace(/[^0-9]/g, '')

      if (numericValue.length === 0 || numericValue < 1) {
        this.errorLength = true
        return ''
      }
      this.errorLength = false
      // eslint-disable-next-line radix
      return parseInt(numericValue)
    },
    formatWidth(value) {
      if (value === '') {
        this.errorWidth = true
        return value
      }

      const numericValue = value.replace(/[^0-9]/g, '')

      if (numericValue.length === 0 || numericValue < 1) {
        this.errorWidth = true
        return ''
      }
      this.errorWidth = false
      // eslint-disable-next-line radix
      return parseInt(numericValue)
    },
    formatHeight(value) {
      if (value === '') {
        this.errorHeight = true
        return value
      }

      const numericValue = value.replace(/[^0-9]/g, '')

      if (numericValue.length === 0 || numericValue < 1) {
        this.errorHeight = true
        return ''
      }
      this.errorHeight = false
      // eslint-disable-next-line radix
      return parseInt(numericValue)
    },
    cancelVolumetric() {
      this.volumetric = {}
      this.errorLength = false
      this.errorWidth = false
      this.errorHeight = false
      this.selected.pop()
      this.$bvModal.hide('modal-blocking-volumetric')
    },
    disabledSubmitVolumetric() {
      if (this.volumetric.product_length === '' || this.volumetric.product_width === '' || this.volumetric.product_height === '') return true
      return false
    },
    submitVolumetric() {
      newAxiosIns.put(`/komship/api/v1/product-warehouses/products/${this.volumetric.id}/dimensions`, {
        height: parseInt(this.volumetric.product_height, 0),
        length: parseInt(this.volumetric.product_length, 0),
        width: parseInt(this.volumetric.product_width, 0),
      }).then(() => {
        this.$bvModal.hide('modal-blocking-volumetric')
        this.volumetric = {}
        this.errorLength = false
        this.errorWidth = false
        this.errorHeight = false
        this.fetchProduct()
      }).catch(() => {
        this.$bvModal.hide('modal-blocking-volumetric')
        this.volumetric = {}
        this.errorLength = false
        this.errorWidth = false
        this.errorHeight = false
        this.selected.pop()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal update data, silahkan coba lagi',
            variant: 'danger',
          },
        }, 2000)
      })
    },
    formatPackingLayer(value) {
      const numericValue = value.replace(/[^0-9]/g, '')

      if (numericValue === '') {
        return 0
      }

      if (numericValue === '0') {
        return 1
      }

      if (parseInt(numericValue, 0) > 9) {
        return 9
      }

      return numericValue
    },
    isProductIncomplete(product) {
      if (product.is_bundling === 1) {
        // Check if any is_registered is 1
        const allRegistered = product.bundle_component.every(item => item.is_registered === 1)

        // If there are registered items, return false
        if (allRegistered) {
          return false
        }

        // If all items are registered, check if any product_id is missing in selected items
        const productIdsInBundle = product.bundle_component.filter(item => item.is_registered === 0).map(item => item.product_id)
        const selectedIds = this.selected.map(item => item.id)
        const isProductIdsMissing = !productIdsInBundle.every(productId => selectedIds.includes(productId))

        if (isProductIdsMissing) {
          const indexToRemove = this.selected.findIndex(obj => obj.id === product.id)

          // Remove the object if found
          if (indexToRemove !== -1) {
            this.selected.splice(indexToRemove, 1)
          }
        }

        // Return false if product_id is missing
        return isProductIdsMissing
      }
      // Otherwise return true (not a bundling product)
      return true
    },
  },
}
</script>
<style scoped>
 .status-tersedia {
  background-color: #DCF3EB;
  padding: 5px 10px;
  border-radius: 4px;
  color: #34A770;
 }
 .image-product{
  object-fit: cover;
  object-position: center center;
  width: 50px!important;
  height: 50px!important;
}
 .status-penuh {
  background-color: #FFEDED;
  padding: 5px 10px;
  border-radius: 4px;
  color: #E31A1A;
 }
</style>
